@tailwind base;
@tailwind components;
@tailwind utilities;

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: lighter;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

body, #root {
  height: 100%;
}

html, body, #root {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

html {
  background-color: #ffffff;
  color: #2B2B2B;
}

html.dark {
  background-color: #2B2B2B;
  color: #ffffff;
}

/* Styles personnalisés pour les champs de saisie de mot de passe */

/* For Microsoft Edge */
input[type="password"]::-ms-reveal {
  display: none;
}

/* Styles personnalisés pour la scrollbar */

/* Pour les navigateurs WebKit (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Track transparent */
}

::-webkit-scrollbar-thumb {
  background-color: #FFFFFF; /* Thumb toujours blanc */
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #e0e0e0; /* Optionnel : légère variation au survol */
}

/* Pour Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #FFFFFF transparent; /* Thumb blanc, track transparent */
}

/* Neutraliser l'apparence des champs autofill sur Chrome, Safari et Firefox */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: inherit !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: inherit !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

/* Désactiver également l'autofill dans Firefox */
input:-moz-autofill {
  box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent !important;
  color: inherit !important;
  -moz-text-fill-color: inherit !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

@keyframes sunSet {
    0% {
      transform: translate(0, 0) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translate(50px, 50px) rotate(360deg);
      opacity: 0;
    }
}

@keyframes sunRise {
    0% {
      transform: translate(-50px, 50px) rotate(-360deg);
      opacity: 0;
    }
    100% {
      transform: translate(0, 0) rotate(0deg);
      opacity: 1;
    }
}

@keyframes moonSet {
    0% {
      transform: translate(0, 0) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translate(50px, 50px) rotate(360deg);
      opacity: 0;
    }
}

@keyframes moonRise {
    0% {
      transform: translate(-50px, 50px) rotate(-360deg);
      opacity: 0;
    }
    100% {
      transform: translate(0, 0) rotate(0deg);
      opacity: 1;
    }
}

.animate-sun-set {
    animation: sunSet 0.5s forwards;
}

.animate-sun-rise {
    animation: sunRise 0.5s forwards;
}

.animate-moon-set {
    animation: moonSet 0.5s forwards;
}

.animate-moon-rise {
    animation: moonRise 0.5s forwards;
}

@keyframes propagate {
  0% {
    clip-path: circle(0% at var(--x) var(--y));
  }
  100% {
    clip-path: circle(150% at var(--x) var(--y));
  }
}

.animate-darkmode {
  animation: propagate 0.7s ease-in-out forwards;
}